import React, { useEffect, useState } from "react";
import { Assessment } from "../models";
import { fetchAssessmentsCreatedByUser } from "../firebase/services/assessmentsService";
import { Link } from "react-router-dom";

const AssessmentsPage = () => {
  const [fetching, setFetching] = useState(false);
  const [assessments, setAssessments] = useState([] as Assessment[]);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      
      const fetchedAssessments = await fetchAssessmentsCreatedByUser();
      let filteredAndSortedAssessments = fetchedAssessments.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

      let fsbtChartData = [] as any[];
      let sibtChartData = [] as any[];

      console.log(filteredAndSortedAssessments);

      filteredAndSortedAssessments.forEach(assessment => {
        if (assessment.workoutProtocol === 'Four Stage Balance') assessment.workoutProtocol = 'Four Stage Balance Test'
        else if (assessment.workoutProtocol === 'SIBT')  assessment.workoutProtocol = 'Sensory Integration Balance Test'
      });

      setAssessments(filteredAndSortedAssessments );
      setFetching(false);
    };
  
    fetchData();
  }, []);

  return (
    <>
      <div className="bg-white px-6 pt-12 sticky top-0">
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold">Your Assessments</h1>
        </div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <div className="bg-white pt-4">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="pl-8 text-left">Assessment</th>
                <th>Participant</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {assessments
                .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                .map((assessment) => (
                <tr key={assessment.refId}>
                  <td>
                    <Link to={`/assessment/${assessment.refId}`}>
                      <div className="pl-4 hover:underline">
                        <h3 className="font-bold">{assessment.workoutProtocol}</h3>
                      </div>
                    </Link>
                  </td>
                  <td className="text-center">{assessment.participantName}</td>
                  <td className="text-center">{new Date(assessment.createdAt.seconds * 1000).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default AssessmentsPage;