import React, { useState, useEffect } from 'react';
import { createDashboardToken, listDashboardTokens, revokeToken } from '../firebase/services/tokenService';
// If date-fns isn't installed, let's use alternative date formatting
import { format as formatDate } from 'date-fns';
import { formatDistance } from 'date-fns';
import { 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Typography,
  Chip,
  CircularProgress,
  IconButton,
  Box,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { 
  Refresh as RefreshIcon, 
  FileCopy as CopyIcon, 
  Delete as DeleteIcon,
  Add as AddIcon
} from '@mui/icons-material';
import withAuthentication from '../hoc/withAuthentication';

interface Token {
  id: string;
  userId: string;
  createdAt: number;
  expiresAt: number;
  revoked: boolean;
  revokedAt?: number;
  tokenDescription: string;
}

const ManageTokens: React.FC = () => {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [loading, setLoading] = useState(true);
  const [newTokenDialog, setNewTokenDialog] = useState(false);
  const [tokenDescription, setTokenDescription] = useState('IT Access Token');
  const [expiryDays, setExpiryDays] = useState(30);
  const [newToken, setNewToken] = useState<string>('');
  const [showToken, setShowToken] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tokenToDelete, setTokenToDelete] = useState<string | null>(null);

  // Load tokens on component mount
  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const tokensList = await listDashboardTokens();
      setTokens(tokensList);
    } catch (error) {
      showSnackbar('Failed to load tokens', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateToken = async () => {
    try {
      const result = await createDashboardToken(tokenDescription, expiryDays);
      setNewToken(result.token);
      setShowToken(true);
      fetchTokens(); // Refresh token list
      showSnackbar('Token created successfully', 'success');
    } catch (error) {
      showSnackbar('Failed to create token', 'error');
    }
  };

  const handleRevokeToken = async (tokenId: string) => {
    try {
      await revokeToken(tokenId);
      fetchTokens(); // Refresh token list
      showSnackbar('Token revoked successfully', 'success');
      setDeleteDialogOpen(false);
      setTokenToDelete(null);
    } catch (error) {
      showSnackbar('Failed to revoke token', 'error');
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    showSnackbar('Copied to clipboard', 'success');
  };

  const showSnackbar = (message: string, severity: 'success' | 'error') => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleCloseNewTokenDialog = () => {
    setNewTokenDialog(false);
    setNewToken('');
    setShowToken(false);
    setTokenDescription('IT Access Token');
    setExpiryDays(30);
  };

  const handleOpenDeleteDialog = (tokenId: string) => {
    setTokenToDelete(tokenId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setTokenToDelete(null);
  };

  const getTokenStatus = (token: Token) => {
    if (token.revoked) {
      return <Chip label="Revoked" color="error" size="small" />;
    }
    
    const now = Date.now();
    if (token.expiresAt < now) {
      return <Chip label="Expired" color="warning" size="small" />;
    }
    
    return <Chip label="Active" color="success" size="small" />;
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <Typography variant="h4" component="h1">
          Manage Access Tokens
        </Typography>
        <div className="flex gap-2">
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AddIcon />}
            onClick={() => setNewTokenDialog(true)}
          >
            Create New Token
          </Button>
          <Button 
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={fetchTokens}
          >
            Refresh
          </Button>
        </div>
      </div>

      <Typography variant="body1" paragraph>
        Access tokens allow other parties to access data for support purposes. 
        These tokens are valid for a limited time and can be revoked at any time.
      </Typography>

      {loading ? (
        <div className="flex justify-center py-8">
          <CircularProgress />
        </div>
      ) : tokens.length === 0 ? (
        <Paper className="p-6 text-center">
          <Typography variant="h6">No tokens found</Typography>
          <Typography variant="body2" color="textSecondary">
            Create a new token to give another party access to your data
          </Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tokens.map((token) => (
                <TableRow key={token.id}>
                  <TableCell>{token.tokenDescription}</TableCell>
                  <TableCell>
                    {token.createdAt 
                      ? formatDate(new Date(token.createdAt), 'MMM d, yyyy')
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {token.expiresAt
                      ? `${formatDate(new Date(token.expiresAt), 'MMM d, yyyy')} (${formatDistance(new Date(token.expiresAt), new Date(), { addSuffix: true })})`
                      : 'N/A'}
                  </TableCell>
                  <TableCell>
                    {getTokenStatus(token)}
                  </TableCell>
                  <TableCell align="right">
                    {!token.revoked && (
                      <IconButton 
                        color="error" 
                        onClick={() => handleOpenDeleteDialog(token.id)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* New Token Dialog */}
      <Dialog open={newTokenDialog} onClose={handleCloseNewTokenDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {newToken ? 'New Token Created' : 'Create New Dashboard Access Token'}
        </DialogTitle>
        <DialogContent>
          {!newToken ? (
            <>
              <DialogContentText>
                Create a new token for others to access your data for support purposes.
                The token will expire automatically after the specified period.
              </DialogContentText>
              <TextField
                margin="normal"
                label="Token Description"
                fullWidth
                value={tokenDescription}
                onChange={(e) => setTokenDescription(e.target.value)}
                helperText="Enter a description to help you identify this token later"
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Expiry Period</InputLabel>
                <Select
                  value={expiryDays}
                  label="Expiry Period"
                  onChange={(e) => setExpiryDays(Number(e.target.value))}
                >
                  <MenuItem value={7}>7 days</MenuItem>
                  <MenuItem value={14}>14 days</MenuItem>
                  <MenuItem value={30}>30 days</MenuItem>
                  <MenuItem value={60}>60 days</MenuItem>
                  <MenuItem value={90}>90 days</MenuItem>
                </Select>
              </FormControl>
            </>
          ) : (
            <>
              <DialogContentText>
                Your token has been created. Share this token with the party who needs access to your data.
                This token will expire in {expiryDays} days and can be revoked at any time.
              </DialogContentText>
              <Box position="relative" mt={2} mb={2}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  value={newToken}
                  InputProps={{
                    readOnly: true,
                    type: showToken ? 'text' : 'password',
                  }}
                />
                <Box position="absolute" right={8} top={8}>
                  <IconButton 
                    onClick={() => copyToClipboard(newToken)}
                    size="small"
                  >
                    <CopyIcon />
                  </IconButton>
                </Box>
              </Box>
              <Button
                variant="outlined"
                onClick={() => setShowToken(!showToken)}
                size="small"
              >
                {showToken ? 'Hide Token' : 'Show Token'}
              </Button>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTokenDialog}>
            {newToken ? 'Close' : 'Cancel'}
          </Button>
          {!newToken && (
            <Button onClick={handleCreateToken} variant="contained" color="primary">
              Create Token
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Revoke Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to revoke this token? This action cannot be undone.
            Once revoked, the token will no longer provide access to your data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button 
            onClick={() => tokenToDelete && handleRevokeToken(tokenToDelete)} 
            color="error" 
            variant="contained"
          >
            Revoke Token
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default withAuthentication(ManageTokens);