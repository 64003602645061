import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import useAuthState from './hooks/useAuthState';
import { StateProvider } from './context';

import Nav from './components/nav';

import ParticipantsPage from './pages/participants';
import AssessmentsPage from './pages/assessments';

import ParticipantPage from './pages/participant';
import AssessmentPage from './pages/assessment';
import ManageTokensPage from './pages/manageTokens';

import SignInPage from './pages/signIn';
import ForgotPasswordPage from './pages/forgot-password';
import ErrorPage from './pages/error';
import SignOutPage from './pages/signOut';

function App() {
  const { user, loading } = useAuthState();

  if (loading) {
    return <div className="h-screen w-screen flex flex-col items-center justify-center gap-6">
      <i className="fas fa-spinner fa-spin text-3xl md:text-7xl"></i>
      <h3 className="font-bold text-2xl md:text-4xl text-center">Loading...</h3>
    </div>;
  }

  // Create a function that takes child and outputs JSX div with child inside
  const SignedInLayout = ({ children }) => {
    return (
      <div className="flex flex-col md:flex-row h-screen w-screen">
        <Nav />
        <div className="flex-grow overflow-y-scroll h-screen">
          {children}
        </div>
      </div>
    )
  }

  const wrapChildOrRedirect = (child) => {
    return user ? <SignedInLayout>{child}</SignedInLayout> : <Navigate to="/signin" replace />;
  }

  const router = createBrowserRouter([
    { path: "/", element: wrapChildOrRedirect(<ParticipantsPage />) },
    { path: "/participant/:id", element: wrapChildOrRedirect(<ParticipantPage />) },
    { path: "/assessments", element: wrapChildOrRedirect(<AssessmentsPage />) },
    { path: "/assessment/:id", element: wrapChildOrRedirect(<AssessmentPage />) },
    { path: "/manage-tokens", element: wrapChildOrRedirect(<ManageTokensPage />) },
    { path: "/signin", element: user ? <Navigate to="/" replace /> : <SignInPage /> },
    { path: "/forgot-password", element: user ? <Navigate to="/" replace /> : <ForgotPasswordPage /> },
    { path: "/signout", element: <SignOutPage /> },
    { path: "/404", element: <ErrorPage /> },
    { path: "*", element: <Navigate to="/404" replace /> }
  ]);

  return (
    <StateProvider>
      <RouterProvider router={router} />
    </StateProvider>
  );
}

export default App;