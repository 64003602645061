import React, {useState, useEffect, createContext } from "react";
import { getCurrentUserId } from "./utils";

const StateContext = createContext({
  isSideNavOpen: false,
  toggleSideNav: () => {},
});

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const toggleSideNav = () => setIsSideNavOpen(!isSideNavOpen);


  return (
    <StateContext.Provider value={{isSideNavOpen, toggleSideNav }}>
      {children}
    </StateContext.Provider>
  );
}

export {StateContext, StateProvider};