import React from 'react';
import { Page, Text, Document, StyleSheet, View, Image, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

// Define styles for the PDF
const styles = StyleSheet.create({
  page: {
    padding: 20,
    backgroundColor: '#ffffff',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'flex-end',
  },
  headerEnd: {
    textAlign: 'right',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  rightText: {
    textAlign: 'right',
  },
  image: {
    height: 50,
  },
  headerText: {
    fontSize: 16,
  },
  sectionHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 16,
  },
  miniHeader: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: 10,
    marginTop: 10,
  },
  chartImage: {
    width: '100%',
    height: 'auto',
    marginBottom: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flexGrow: {
    flexGrow: 1,
  },
  imageParent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentCard: {
    padding: 10,
    marginBottom: 10,
    border: 1,
    borderColor: '#d3d3d3',
    borderStyle: 'solid',
    backgroundColor: '#fafafa',
    borderRadius: 10,
  },
  divider: {
    margin: '3px 0',
    borderBottom: 1,
    borderColor: '#000',
    width: '100%',
    height: 1,
  }

});

interface BalanceTestReportProps {
  testName: string;
  date: string;
  patientString: string;
  gridImageUrl: string;
  chartImageUrl: string;
  pies0ImageUrl: string;
  pies1ImageUrl: string;
  pies2ImageUrl: string;
  pies3ImageUrl: string;
}

const BalanceTestReport: React.FC<BalanceTestReportProps> = ({
  testName,
  date,
  patientString,
  gridImageUrl,
  chartImageUrl,
  pies0ImageUrl,
  pies1ImageUrl,
  pies2ImageUrl,
  pies3ImageUrl
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>

        <View style={styles.header}>
          <Image style={styles.image} src="/pdfLogo.png" />
        </View>

        <View style={styles.header}>
          <View>
            <Text style={styles.miniHeader}>MFX Health - {date}</Text>
            <Text style={styles.headerText}>{testName}</Text>
          </View>

          <View style={styles.headerEnd}>
            <Text style={styles.miniHeader}>Patient</Text>
            <Text style={styles.headerText}>{patientString}</Text>
          </View>
        </View>

        <View style={styles.divider} />
        
        <Text style={{fontSize: 10, marginBottom: 10, textAlign: 'center'}}>
          Balance Test Results
        </Text>

        <View>
          <View style={{marginBottom: 20}}>
            <Image style={styles.chartImage} src={gridImageUrl} />
          </View>

          <View style={{marginBottom: 20}}>
            <Image style={styles.chartImage} src={chartImageUrl} />
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.flexGrow, styles.imageParent, {width: '48%'}]}>
            <Image style={styles.chartImage} src={pies0ImageUrl} />
          </View>

          <View style={[styles.flexGrow, styles.imageParent, {width: '48%'}]}>
            <Image style={styles.chartImage} src={pies1ImageUrl} />
          </View>
        </View>

        <View style={styles.row}>
          <View style={[styles.flexGrow, styles.imageParent, {width: '48%'}]}>
            <Image style={styles.chartImage} src={pies2ImageUrl} />
          </View>

          <View style={[styles.flexGrow, styles.imageParent, {width: '48%'}]}>
            <Image style={styles.chartImage} src={pies3ImageUrl} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BalanceTestReport;