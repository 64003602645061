import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { Assessment } from "../models";
import { fetchAssessmentById } from "../firebase/services/assessmentsService";
import { fetchUserProfileById } from "../firebase/services/userService";
import { getConditionAverage } from "../utils";

import html2canvas from 'html2canvas';
import { pdf } from '@react-pdf/renderer';
import BalanceTestReport from "../components/pdfs/balanceTestReport";
import { saveAs } from 'file-saver';

import WorkoutDetails from "../components/workoutDetails"

import { ResponsiveContainer, PieChart, Pie, Tooltip, BarChart, Bar, XAxis, YAxis, } from "recharts";

const AssessmentPage = () => {
  const { id: assessmentId } = useParams<{ id: string }>();
  const [assessment, setAssessment] = useState({} as Assessment);
  const [participantsShortHandId, setParticipantsShortHandId] = useState("");
  const [fetching, setFetching] = useState(true);
  const [liveRefreshing, setLiveRefreshing] = useState(false);


  useEffect(() => {
    async function fetchData() {
      if (!assessmentId) {
        setFetching(false);
        return;
      }

      
      const fetchedAssessment = await fetchAssessmentById(assessmentId);

      if (!fetchedAssessment) {
        setFetching(false);
        return;
      }

      let userProfile = await fetchUserProfileById(fetchedAssessment.participantUserId);
      if (userProfile) {
        setParticipantsShortHandId(userProfile.participantId);
      }

      setAssessment(fetchedAssessment);
      setFetching(false);
    };

    fetchData();
  }, [assessmentId]);

  let sibtConditionKeys = [
    "Eyes Open", 
    "Eyes Closed", 
    "Eyes Open Arms Raised",
    "Eyes Closed Arms Raised",
  ];

  let fourStageConditionKeys = [
    "Feet Together",
    "Semi-Tandem",
    "Tandem",
    "Single Leg",
  ]

  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [includePatientName, setIncludePatientName] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const exportChartToImage = async (chartElementId: string) => {
    const chartElement = document.getElementById(chartElementId);
    if (!chartElement) {
      throw new Error(`Element with id ${chartElementId} not found`);
    }
    const canvas = await html2canvas(chartElement, {
      scale: 2, // Increase resolution
      useCORS: true,
      logging: false,
      width: chartElement.offsetWidth,
      height: chartElement.offsetHeight,
      onclone: (documentClone) => {
        // Make sure all chart elements are fully visible in the clone
        const clonedElement = documentClone.getElementById(chartElementId);
        if (clonedElement) {
          clonedElement.style.height = 'auto';
          clonedElement.style.overflow = 'visible';
        }
      }
    });
    return canvas.toDataURL('image/png');
  };

  const prepareBalancePDF = async () => {
    setPdfDownloading(true);
    const gridImage = await exportChartToImage("balance-grid");
    const chartImage = await exportChartToImage("balance-chart");
    const pies0Image = await exportChartToImage("balance-pies-0");
    const pies1Image = await exportChartToImage("balance-pies-1");
    const pies2Image = await exportChartToImage("balance-pies-2");
    const pies3Image = await exportChartToImage("balance-pies-3");

    let patientString = includePatientName ? assessment.participantName : participantsShortHandId;
    let date = new Date(assessment.createdAt.seconds * 1000).toDateString();
    
    const doc = <BalanceTestReport {
      ...{
        testName: assessment.workoutProtocol === "SIBT" ? "Sensory Integration Balance Test" : `${assessment.workoutProtocol} Test`,
        date,
        patientString,
        gridImageUrl: gridImage,
        chartImageUrl: chartImage,
        pies0ImageUrl: pies0Image,
        pies1ImageUrl: pies1Image,
        pies2ImageUrl: pies2Image,
        pies3ImageUrl: pies3Image,
      }
    } />;
    const blob = await pdf(doc).toBlob();
    saveAs(blob, `${patientString} - ${assessment.workoutProtocol} Test - ${date}.pdf`);
    setPdfDownloading(false);
  }

  if (fetching) return <div>Loading...</div>;

  return (
    <>
      {pdfModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style={{zIndex: 999}}>
          <div className="bg-white p-6 rounded-md flex flex-col">
            <h2 className="text-xl font-bold">Download PDF Report</h2>
            
            <div className="flex items-center mt-4">
              <input 
                type="checkbox" 
                id="includePatientName" 
                checked={includePatientName}
                onChange={(e) => setIncludePatientName(e.target.checked)}
              />
              <label htmlFor="includePatientName" className="ml-2">Include patient name</label>
            </div>

            <button 
              className="bg-mfx-blue text-white px-2 py-1 rounded-md mt-4 font-bold"
              onClick={prepareBalancePDF}
              disabled={pdfDownloading}
            >
              <i className={`fa-solid fa-${pdfDownloading ? 'spinner-third animate-spin' : 'download'} mr-2`}></i>
              Download PDF
            </button>
            <button 
              className="text-mfx-blue mt-2"
              onClick={() => setPdfModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <div className="container mx-auto px-4 pb-12">
        <div className="flex items-center gap-4">

          <button 
            className="text-mfx-blue mt-6 mb-4 font-bold"
            onClick={() => window.history.back()}
          >
            <i className="fa-solid fa-arrow-left mr-2"></i>
            Back to {assessment.participantName}'s Assessments
          </button>

          <button 
            className={`ml-auto ${liveRefreshing ? 'bg-red-400' : 'bg-green-500'} text-white px-2 py-1 rounded-md mt-6 mb-4 font-bold`}
            onClick={() => setLiveRefreshing(!liveRefreshing)}
          >
            <i className={`fa-solid fa-${liveRefreshing ? 'pause' : 'play'} mr-2`}></i>
            {liveRefreshing ? 'Pause' : 'Live Refresh'}
          </button>

          {assessment.balanceConditions && (
            <button
              className="bg-mfx-blue text-white px-2 py-1 rounded-md mt-6 mb-4 font-bold"
              onClick={() => setPdfModalOpen(true)}
            >
              <i className="fa-solid fa-download mr-2"></i>
              Download PDF
            </button>
          )}
        </div>

        <div className="flex flex-col grid xl:grid-cols-2 gap-6">

          <div className="bg-white p-6 flex gap-4 items-end">
            <div className="flex items-center justify-center h-16 w-16 bg-mfx-blue text-white rounded-md">
              <i className="fa-solid fa-person-running-fast text-3xl"></i>
            </div>
            <div className="pb-1">
              <p className="text-sm text-gray-500">{new Date(assessment.createdAt.seconds * 1000).toDateString()}</p>
              <h2 className="text-xl font-bold">
                {assessment.workoutProtocol === "SIBT" ? "Sensory Integration Balance Test" : assessment.workoutProtocol}
                {(assessment.workoutProtocol === "SIBT" || assessment.workoutProtocol === "Four Stage Balance") && assessment.balanceConditions && (
                  <span>&nbsp;({assessment.balanceConditions[3].timeInBalance} seconds)</span>
                )}
              </h2>
            </div>
          </div>

          <div className="bg-white p-6 flex gap-4 items-end">
            <div className="flex items-center justify-center h-16 w-16 bg-mfx-blue text-white rounded-md">
              <i className="fa-solid fa-user text-3xl"></i>
            </div>
            <div className="pb-1">
              <p className="text-sm text-gray-500">Participant</p>
              <h2 className="text-xl font-bold">{assessment.participantName}</h2>
            </div>
          </div>

          {assessment.balanceConditions 
            ? <>
                <div 
                  id="balance-grid"
                  className="bg-white px-6 py-12 flex justify-center"
                >
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th className="px-2">Time Stable</th>
                        <th className="px-2">Minor Instability</th>
                        <th className="px-2">Major Instability</th>
                      </tr>
                    </thead>
                    <tbody>
                      {assessment.balanceConditions.map((balanceCondition, index) => {
                        let conditionKeys = assessment.workoutProtocol === "SIBT" ? sibtConditionKeys : fourStageConditionKeys; 
                        return (
                        <tr key={index}>
                          <td className="text-right font-bold">{conditionKeys[index]}</td>
                          <td className="text-center">{balanceCondition.timeStable.toFixed(1)}</td>
                          <td className="text-center">{balanceCondition.minorInstability.toFixed(1)}</td>
                          <td className="text-center">{balanceCondition.majorInstability.toFixed(1)}</td>
                        </tr>
                        )
                      })}
                      <tr className="border-t">
                        <td className="text-right font-bold">Average</td>
                        <td className="text-center">{getConditionAverage(assessment, "timeStable")}</td>
                        <td className="text-center">{getConditionAverage(assessment, "minorInstability")}</td>
                        <td className="text-center">{getConditionAverage(assessment, "majorInstability")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="bg-white p-6" id="balance-chart">
                  <h3 className="text-xl font-bold text-center">Percent of time spent stable</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={assessment.balanceConditions.map((balanceCondition, index) => {
                      let conditionKeys = assessment.workoutProtocol === "SIBT" ? sibtConditionKeys : fourStageConditionKeys; 
                      let value = balanceCondition.timeStable / balanceCondition.timeInBalance * 100;
                      return {
                        name: conditionKeys[index],
                        "Stable Time": value,
                        fill: value > 50 
                          ? "#14ab4a" 
                          : value > 20
                            ? "#FFBB28"
                            : "#ff4242"
                      }
                    })}>
                      <XAxis 
                        dataKey="name" 
                        fontSize={12} 
                        
                      />
                      <YAxis
                        domain={[0, 100]} 
                        tickFormatter={(value) => `${value}%`}
                      />
                      <Bar dataKey="Stable Time" fill="#8884d8" />
                      <Tooltip 
                        formatter={(value: string | number) => `${typeof value === 'number' ? value.toFixed(0) : parseFloat(value).toFixed(0)}%`}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {[0, 1, 2, 3].map((index) => {
                  if (!assessment.balanceConditions || !assessment.balanceConditions[index]) return null;
                  return (
                    <div 
                      key={index} 
                      className="bg-white p-6"
                      id={`balance-pies-${index}`}
                    >
                      <h3 className="text-xl font-bold text-center">{assessment.workoutProtocol === "SIBT" ? sibtConditionKeys[index] : fourStageConditionKeys[index]}</h3>
                      <ResponsiveContainer width="100%" height={450}>
                        <PieChart>
                        <Pie 
                          dataKey="value" 
                          data={[
                          { 
                            name: "Time Stable", 
                            value: assessment.balanceConditions ? assessment.balanceConditions[index].timeStable / assessment.balanceConditions[index].timeInBalance * 100 : 0,
                            fill: "#14ab4a" // Green
                          },
                          { 
                            name: "Minor Instability", 
                            value: assessment.balanceConditions ? assessment.balanceConditions[index].minorInstability / assessment.balanceConditions[index].timeInBalance * 100 : 0,
                            fill: "#FFBB28" // Yellow
                          },
                          { 
                            name: "Major Instability", 
                            value: assessment.balanceConditions ? assessment.balanceConditions[index].majorInstability / assessment.balanceConditions[index].timeInBalance * 100 : 0,
                            fill: "#ff4242" // Red
                          },
                          ]} 
                        />
                        <Tooltip formatter={(value: number) => `${value.toFixed(0)}%`} />
                        </PieChart>
                      </ResponsiveContainer>
                      <div className="text-center mt-4">
                        <span className="inline-block mr-4">
                        <span className="inline-block w-3 h-3 mr-1" style={{ backgroundColor: "#14ab4a" }}></span>
                        Time Stable
                        </span>
                        <span className="inline-block mr-4">
                        <span className="inline-block w-3 h-3 mr-1" style={{ backgroundColor: "#FFBB28" }}></span>
                        Minor Instability
                        </span>
                        <span className="inline-block">
                        <span className="inline-block w-3 h-3 mr-1" style={{ backgroundColor: "#ff4242" }}></span>
                        Major Instability
                        </span>
                      </div>
                    </div>
                  )
                })}
              </>
            : <div className="col-span-2">
                <WorkoutDetails workoutData={assessment} liveRefresh={liveRefreshing} />
              </div>
          }
        </div>
      </div>

      
    </>
  );
}

export default AssessmentPage;