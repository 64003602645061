import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Assessment } from "../models";
import { fetchAssessmentsByParticipantId } from "../firebase/services/assessmentsService";
import { Link } from "react-router-dom";
import { getConditionAverage } from "../utils";

import { LineChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, AreaChart } from 'recharts';

import PerformanceComparison from "../components/performanceComparison";

const ParticipantPage = () => {
  const { id: participantId } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("" as string);
  const [fetching, setFetching] = useState(true);

  const tabs = ["Overview", "Assessments", "Performance Comparison"];
  const [tab, setTab] = useState<string>(() => {
    const params = new URLSearchParams(location.search);
    return params.get("tab") || tabs[0];
  });

  const [assessments, setAssessments] = useState([] as Assessment[]);
  const [fsbtChartData, setFsbtChartData] = useState([] as any[]);
  const [sibtChartData, setSibtChartData] = useState([] as any[]);

  useEffect(() => {
    async function fetchData() {
      if (!participantId) {
        setFetching(false);
        return;
      }
      
      const fetchedAssessments = await fetchAssessmentsByParticipantId(participantId);

      let filteredAndSortedAssessments = fetchedAssessments.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);

      let fsbtChartData = [] as any[];
      let sibtChartData = [] as any[];

      console.log(filteredAndSortedAssessments);

      filteredAndSortedAssessments.forEach(assessment => {
        if (assessment.workoutProtocol === 'SIBT' || assessment.workoutProtocol === 'Four Stage Balance') {
          let avgStable = getConditionAverage(assessment, "timeStable");
          let avgMinorInstability = getConditionAverage(assessment, "minorInstability");
          let avgMajorInstability = getConditionAverage(assessment, "majorInstability");
          let stablePercentage = (avgStable / (avgStable + avgMinorInstability + avgMajorInstability)) * 100

          if (assessment.workoutProtocol === 'Four Stage Balance') {
            assessment.workoutProtocol = 'Four Stage Balance Test'
            fsbtChartData.push({
              name: new Date(assessment.createdAt.seconds * 1000).toDateString(),
              "Stability": stablePercentage
            })
          } else {
            assessment.workoutProtocol = 'Sensory Integration Balance Test'
            sibtChartData.push({
              name: new Date(assessment.createdAt.seconds * 1000).toDateString(),
              "Stability": stablePercentage
            })
          }
        }
      });
      setFsbtChartData(fsbtChartData.reverse());
      setSibtChartData(sibtChartData.reverse());

      setAssessments(filteredAndSortedAssessments );
      setFetching(false);
    };
  
    fetchData();
  }, [participantId]);

  const [viewMode, setViewMode] = useState("Cards");
  const [sortBy, setSortBy] = useState("Athletes");

  const handleTabChange = (tabName: string) => {
    setTab(tabName);
    setSortBy("Athletes");
    setSearchQuery("");
    navigate(`?tab=${tabName}`);
  };

  const tabElements = () => {
    return tabs.map((tabName) => {
      return (
        <button
          key={tabName}
          className={`${(tab === tabName) ? "border-[#333333] font-bold" : "border-white"} text-lg px-2 border-b-4`}
          onClick={() => handleTabChange(tabName)}
        >
          {tabName}
        </button>
      );
    });
  };

  return (
    <>
      <div className="bg-white px-6 pt-12 sticky top-0 z-10">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold">{assessments[0]?.participantName || "Participant"}
          </h1>

          <div className="flex items-center gap-4 mt-6">
            {tabElements()}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-6">
        {tab === "Overview" && <>

          {fsbtChartData.length > 0 && (
            <div className="bg-white p-4 mt-4">
              <h3 className="text-xl font-bold">Four Stage Balance Stability</h3>
              <ResponsiveContainer width="100%" height={400}>
                  <AreaChart data={fsbtChartData} >
                  <XAxis dataKey="name" tick={{ fill: 'black' }} />
                  <YAxis 
                    tick={{ fill: 'black' }} 
                    orientation='right' 
                    tickFormatter={(tick) => `${tick}%`} 
                  />
                  <Tooltip 
                    contentStyle={{ backgroundColor: '#444444', color: 'white' }} 
                    itemStyle={{ color: 'white' }} 
                    formatter={(value: string | number) => `${typeof value === 'number' ? value.toFixed(0) : parseFloat(value).toFixed(0)}%`}
                  />
                  <Area type="monotone" dataKey="Stability" stroke="#1d1f2c" fill="#060d3a" />
                  </AreaChart>
              </ResponsiveContainer>
            </div> 
          )}

          {sibtChartData.length > 0 && (
            <div className="bg-white p-4 mt-4">
              <h3 className="text-xl font-bold">Sensory Integration Balance Stability</h3>

              <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={sibtChartData} >
                <XAxis dataKey="name" tick={{ fill: 'black' }} />
                <YAxis 
                  tick={{ fill: 'black' }} 
                  orientation='right' 
                  tickFormatter={(tick) => `${tick}%`} 
                />
                <Tooltip 
                  contentStyle={{ backgroundColor: '#444444', color: 'white' }} 
                  itemStyle={{ color: 'white' }} 
                  formatter={(value: string | number) => `${typeof value === 'number' ? value.toFixed(2) : parseFloat(value).toFixed(2)}%`}
                />
                <Area type="monotone" dataKey="Stability" stroke="#1d1f2c" fill="#060d3a" />
                </AreaChart>
              </ResponsiveContainer>
            </div> 
          )}

        </>}

        {tab === "Assessments" &&
          <div className="bg-white pt-4">
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="pl-8 text-left">Assessment</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {assessments
                  .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)
                  .map((assessment) => (
                  <tr key={assessment.refId}>
                    <td>
                      <Link to={`/assessment/${assessment.refId}`}>
                        <div className="pl-4 hover:underline">
                          <h3 className="font-bold">{assessment.workoutProtocol}</h3>
                        </div>
                      </Link>
                    </td>
                    <td className="text-center">{new Date(assessment.createdAt.seconds * 1000).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        }

        {tab === "Performance Comparison" && 
          <PerformanceComparison 
            ParticipantsAssessments={assessments}
            initialWorkoutProtocol={assessments
              .filter(assessment => assessment.workoutProtocol !== 'Sensory Integration Balance Test' && assessment.workoutProtocol !== 'Four Stage Balance Test')
              [0]?.workoutProtocol || "Squat"}
          />
        }
      </div>
    </>
  );
}

export default ParticipantPage;