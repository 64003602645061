import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendPasswordResetEmail } from "../firebase/auth/authService";

interface FirebaseError extends Error {
  code: string;
}

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleResetPassword = async (event?: React.FormEvent) => {
    event?.preventDefault();
    if (!email) {
      setError("Please enter your email address");
      return;
    }
    
    try {
      await sendPasswordResetEmail(email);
      setSuccess(true);
      setError("");
    } catch (err) {
      console.log(err);

      const error = err as FirebaseError;

      let errorMessage;
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = "No user found with this email.";
          break;
        case 'auth/invalid-email':
          errorMessage = "The email address is not valid.";
          break;
        case 'auth/too-many-requests':
          errorMessage = "Too many attempts. Please try again later.";
          break;
        default:
          errorMessage = "An unexpected error occurred. Please try again.";
      }

      setError(errorMessage);
    }
  }

  return (
    <div className="h-screen w-full grid md:grid-cols-2 items-center justify-center">
      <div className="h-screen w-full">
        <img src="/balance.jpg" alt="background" className="object-cover h-full w-full" />
      </div>

      <div className="bg-white h-full p-4 text-center flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold">Reset Password</h1>
        {!success ? (
          <>
            <p className="mt-4 text-gray-600">Enter your email address and we'll send you a link to reset your password.</p>
            <form className="flex flex-col gap-4 mt-4" onSubmit={handleResetPassword}>
              <input 
                type="text" 
                placeholder="Email" 
                className="primary_input" 
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />

              <button 
                type="submit"
                className="rounded-lg bg-mfx-blue text-white py-2"
              >
                Send Reset Link
              </button>

              {error && <p className="text-red-500">{error}</p>}
            </form>
          </>
        ) : (
          <div className="mt-4 text-green-600">
            <p>Password reset email sent!</p>
            <p className="mt-2 text-sm">Check your inbox for further instructions.</p>
          </div>
        )}
        <Link to="/signin" className="text-xs mt-4 text-mfx-blue hover:underline">Back to Sign In</Link>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;