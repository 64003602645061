import React, { useEffect, useState } from "react";
import { UserProfile } from "../models";
import { fetchProfilesUserIsCreaterOf, fetchMultipleUserProfilesByIds } from "../firebase/services/userService";
import { Link } from "react-router-dom";

import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';

const ParticipantsPage = () => {
  const [displayedAthleteIds, setDisplayedAthleteIds] = useState([] as UserProfile[]);
  const [idsOfAllTeamsUsers, setIdsOfAllTeamUsers] = useState([] as UserProfile[]);
  const [displayedAthleteCount, setDisplayedAthleteCount] = useState(12);
  const [searchQuery, setSearchQuery] = useState("" as string);
  const [fetching, setFetching] = useState(false);
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [tab, setTab] = useState("Teams");
  const tabs = ["Athletes", "Teams"];

  const [participants, setParticipants] = useState([] as UserProfile[]);

  useEffect(() => {
    async function fetchData() {
      setFetching(true);
      
      const userProfiles = await fetchProfilesUserIsCreaterOf();
      console.log(userProfiles);
      setParticipants(userProfiles);
      setFetching(false);
    };
  
    fetchData();
  }, []);

  const [viewMode, setViewMode] = useState("Cards");
  const [sortBy, setSortBy] = useState("Athletes");

  const tabElements = () => {
    return tabs.map((tabName) => {
      return (
        <button
          key={tabName}
          className={`${(tab === tabName) ? "border-[#333333] font-bold" : "border-white"} text-lg px-2 border-b-4`}
          onClick={() => {
            setSortBy("Athletes");
            setSearchQuery("");
            setTab(tabName)
          }}
        >
          {tabName}
        </button>
      );
    });
  };

  const handleSort = (sortBy: string, arrayToSort: any, searchQuery: string) => {
    let sortedArray = arrayToSort.sort((a: any, b: any) => {
      switch (sortBy) {
        case "Athletes":
          return 0;
        case "Members":
          return (b.users ? b.users.length : 0) - (a.users ? a.users.length : 0); 
        case "Name":
          return a.name.localeCompare(b.name);
        default:
          return 0;
      }
    })

    if (searchQuery) {
      sortedArray = sortedArray.filter((item: any) => {
        if (!item.name) return false;
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }

    return sortedArray
  };

  useEffect(() => {
    let idsToDisplay = [...idsOfAllTeamsUsers];
    idsToDisplay = idsToDisplay.slice(0, displayedAthleteCount);
    console.log(idsOfAllTeamsUsers);
    setDisplayedAthleteIds(handleSort(sortBy, idsToDisplay, searchQuery));
  }, [displayedAthleteCount, idsOfAllTeamsUsers, sortBy, searchQuery]);

  return (
    <>
      <div className="bg-white px-6 pt-12 sticky top-0">
        <div className="container mx-auto p-4">
          <h1 className="text-2xl font-bold">Your Participant</h1>
        </div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <div className="bg-white pt-4">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="pl-8 text-left">Participant</th>
                <th>Gender</th>
                <th>Height</th>
                <th>Mass</th>
                <th>Created</th>
              </tr>
            </thead>
            <tbody>
              {participants.map((participant) => (
                <tr 
                  key={participant.id}
                  onClick={() => window.location.href = `/participant/${participant.userId}`}
                  className="cursor-pointer hover:bg-blue-100 transition-all"
                >
                  <td>
                    <Link to={`/participant/${participant.userId}`}>
                      <span className="pl-4 hover:underline">{participant.displayName}</span>
                    </Link>
                  </td>
                  <td className="text-center">{participant.gender || "N/A"}</td>
                  <td className="text-center">{participant.height || "N/A"}</td>
                  <td className="text-center">{participant.mass ? `${participant.mass}${participant.massUnit}` : 'N/A'}</td>
                  <td className="text-center">{new Date(participant.createdAt.seconds * 1000).toDateString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default ParticipantsPage;