import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";

import { fetchUserProfileById, updateDetailsForUserProfile } from "../firebase/services/userService";

type EditUserModalProps = {
  idOfUserToBeEdited: string;
  setIdOfUserToBeEdited: (id: string) => void;
  refreshUserProfile: () => Promise<void>;
};

const EditUserModal: React.FC<EditUserModalProps> = ({ idOfUserToBeEdited, setIdOfUserToBeEdited, refreshUserProfile }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mass, setMass] = useState(0);
  const [massUnit, setMassUnit] = useState("Kg");
  const [attemptingUpdate, setAttemptingUpdate] = useState(false);
  const [errorUpdatingAthlete, setErrorUpdatingAthlete] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  useEffect(() => {
    async function fetchUserDetails() {
      const userProfile = await fetchUserProfileById(idOfUserToBeEdited);
      if (userProfile) {
        setDisplayName(userProfile.displayName);
        setEmail(userProfile.email || "");
        setMass(userProfile.mass || 0);
        setMassUnit(userProfile.massUnit || "lbs");
      }
    }

    idOfUserToBeEdited && idOfUserToBeEdited !== "" && fetchUserDetails();
  }, [idOfUserToBeEdited]);

  const attemptUpdateUserDetails = async () => {
    setAttemptingUpdate(true);
    try {
      // Keep the existing email value when updating
      await updateDetailsForUserProfile(idOfUserToBeEdited, displayName, email, mass, massUnit);
      await refreshUserProfile();
      setIdOfUserToBeEdited("");
    } catch (error) {
      setErrorUpdatingAthlete(true);
      console.error("Failed to update user details", error);
    }
    setAttemptingUpdate(false);
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col md:min-w-[500px]">
        <h1 className="text-2xl font-bold text-center">Edit User Details</h1>

        <form onSubmit={(e) => {
          e.preventDefault();
          attemptUpdateUserDetails();
        }}>
          <p className="mt-6">User Name:</p>
          <input
            type="text"
            required={true}
            placeholder="User name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
          />


          <p className="mt-2">Mass:</p>
          <div className="flex gap-2 md:gap-4">
            <input
              type="number"
              required={true}
              placeholder="User mass"
              value={mass}
              onChange={(e) => setMass(parseInt(e.target.value))}
              className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
            />

            <select
              value={massUnit}
              onChange={(e) => setMassUnit(e.target.value)}
              className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
            >
              <option value="Kg">Kg</option>
              <option value="lbs">lbs</option>
            </select>
          </div>

          {errorUpdatingAthlete && <p className="text-red-500 mt-2">Error updating user details. Please try again.</p>}

          <button 
            disabled={attemptingUpdate}
            className="bg-white text-black rounded-lg px-6 py-2 font-bold mt-4 w-full">{attemptingUpdate ? <i className="fas fa-spinner fa-spin"></i> : "Update Details"}</button>
        </form>

        <button 
          disabled={attemptingUpdate}
          onClick={() => setIdOfUserToBeEdited("")} className="border-2 border-white rounded-lg px-6 py-2 font-bold mt-2">Cancel</button>
      </div>
    </div>
  );
};

export default EditUserModal;