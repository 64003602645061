import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthState from '../hooks/useAuthState';

const withAuthentication = <P extends object>(Component: React.ComponentType<P>) => {
  return function WithAuthenticationWrapper(props: P) {
    const { user, loading } = useAuthState();

    if (loading) {
      return (
        <div className="h-screen w-screen flex flex-col items-center justify-center gap-6">
          <i className="fas fa-spinner fa-spin text-3xl md:text-7xl"></i>
          <h3 className="font-bold text-2xl md:text-4xl text-center">Loading...</h3>
        </div>
      );
    }

    if (!user) {
      return <Navigate to="/signin" replace />;
    }

    return <Component {...props} />;
  };
};

export default withAuthentication;