import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBDl07T1wGCi9T8CFPgeRLMi8WPAwtisaU",
  authDomain: "mfx-health.firebaseapp.com",
  projectId: "mfx-health",
  storageBucket: "mfx-health.appspot.com",
  messagingSenderId: "22705461152",
  appId: "1:22705461152:web:09e61538046f04094676c5",
  measurementId: "G-2P2VMB156W"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app, 'us-central1');

// Connect to the Functions emulator if in development mode
if (process.env.NODE_ENV === 'development') {
  // Uncomment the line below when testing with Firebase emulators
  // connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { db, auth, storage, functions };