import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase_config';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

interface TokenData {
  id: string;
  userId: string;
  createdAt: number;
  expiresAt: number;
  revoked: boolean;
  revokedAt?: number;
  lastUsed?: number;
  tokenDescription: string;
}

export const createDashboardToken = async (description: string = 'IT Access Token', expiryDays: number = 30): Promise<{token: string, tokenId: string, expiresAt: number}> => {
  try {
    const createTokenFunction = httpsCallable(functions, 'createDashboardToken');
    
    const result = await createTokenFunction({ 
      description, 
      expiryDays 
    });
    
    const data = result.data as {
      token: string;
      tokenId: string;
      expiresAt: number;
      description: string;
    };
    
    return {
      token: data.token,
      tokenId: data.tokenId,
      expiresAt: data.expiresAt
    };
  } catch (error) {
    console.error('Error creating dashboard token:', error);
    throw new Error('Failed to create dashboard token');
  }
};

export const revokeToken = async (tokenId: string): Promise<boolean> => {
  try {
    const revokeTokenFunction = httpsCallable(functions, 'revokeDashboardToken');
    
    const result = await revokeTokenFunction({ tokenId });
    return (result.data as { success: boolean }).success;
  } catch (error) {
    console.error('Error revoking token:', error);
    throw new Error('Failed to revoke token');
  }
};

export const listDashboardTokens = async (): Promise<TokenData[]> => {
  try {
    const listTokensFunction = httpsCallable(functions, 'listDashboardTokens');
    
    console.log('Calling listDashboardTokens function...');
    const result = await listTokensFunction();
    console.log('Function result:', result);
    
    if (!result.data || !(result.data as any).tokens) {
      console.error('Unexpected response format:', result.data);
      throw new Error('Unexpected response format from server');
    }
    
    return (result.data as { tokens: TokenData[] }).tokens;
  } catch (error: any) {
    console.error('Error listing tokens:', error);
    console.error('Error details:', error.code, error.message, error.details);
    throw new Error(`Failed to list tokens: ${error.message || 'Unknown error'}`);
  }
};

export const signInWithToken = async (token: string): Promise<void> => {
  try {
    const auth = getAuth();
    await signInWithCustomToken(auth, token);
    
    // After signing in with the token, optionally update its usage stats
    const updateUsageFunction = httpsCallable(functions, 'updateTokenUsage');
    await updateUsageFunction();
  } catch (error) {
    console.error('Error signing in with token:', error);
    throw new Error('Failed to sign in with token');
  }
};