import { db } from '../firebase_config';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { getCurrentUserId } from '../../utils';
import { Assessment } from '../../models';

export const fetchAssessmentsCreatedByUser = async (): Promise<Assessment[]> => {
  const currentUserId = getCurrentUserId(); 
  const assessmentsCollection = collection(db, 'Assessments');

  let q = query(assessmentsCollection, where('createdBy', '==', currentUserId));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data() as Assessment);
};

export const fetchAssessmentById = async (assessmentId: string): Promise<Assessment | null> => {
  const currentUserId = getCurrentUserId();
  const assessmentsCollection = collection(db, 'Assessments');

  let q = query(
    assessmentsCollection, 
    where('createdBy', '==', currentUserId), 
    where('refId', '==', assessmentId)
  );

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  return querySnapshot.docs[0].data() as Assessment;
};

export const fetchAssessmentsByParticipantId = async (participantId: string): Promise<Assessment[]> => {
  const currentUserId = getCurrentUserId();
  const assessmentsCollection = collection(db, 'Assessments');

  let q = query(
    assessmentsCollection, 
    where('createdBy', '==', currentUserId),
    where('participantUserId', '==', participantId),
  );
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => doc.data() as Assessment);
}