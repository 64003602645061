import React, { useEffect, useState } from "react";
import { fetchExerciseSetsByWorkoutId } from "../firebase/services/workoutService";
import { metricsForComparison, lineChartColorCodes, imageForWorkoutProtocol } from "../constants";
import { Assessment, Set } from "../models";
import { format } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, Legend } from 'recharts';
import { formatStatValue, getMetricAverageFromArray, ucFirst } from '../utils';

import VelocitySequenceDiagram from "./velocitySequenceDiagram";
import ComparisonCalendar from './calendarForComparison';
import ExerciseSelect from './exerciseSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import InsightsIcon from '@mui/icons-material/Insights';

type ComparisonProps = {
  ParticipantsAssessments: Assessment[];
  initialWorkoutProtocol: string;
}

const PerformanceComparison: React.FC<ComparisonProps> = ({ ParticipantsAssessments, initialWorkoutProtocol }) => {
  const [comparedStats, setComparedStats] = useState(["peakPower"] as string[]);
  const [movementType, setMovementType] = useState("concentricMovement");
  const [comparisonLoad, setComparisonLoad] = useState(null as null | number);
  const [comparisonExercise, setComparisonExercise] = useState("" as string);
  const [selectExerciseOpen, setSelectExerciseOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(initialWorkoutProtocol as string);
  const [chartView, setChartView] = useState("Line" as "Line" | "VSD");
  const [loadUnit, setLoadUnit] = useState("lbs");
  const [processingComparison, setProcessingComparison] = useState(false);
  const [comparisonChartData, setComparisonChartData] = useState([] as {[key: string]: any}[]);
  const [comparisonData, setComparisonData] = useState([] as {[key: string]: any}[]);
  const [hasAttemptedComparison, setHasAttemptedComparison] = useState(false);
  const [comparedDates, setComparedDates] = useState([{from: new Date(new Date().setDate(new Date().getDate() - 90)), to: new Date(), calendarOpen: false, clickedFrom: null}] as {from: Date | null, to: Date | null, calendarOpen: boolean, clickedFrom: null | string}[]);


  const spliceByIndex = (index: number, valToSplice: string) => {
    switch (valToSplice) {
      case "dates":
        const newComparedDates = [...comparedDates];
        newComparedDates.splice(index, 1);
        setComparedDates(newComparedDates);
        break;
      case "metrics":
        const newComparedStats = [...comparedStats];
        newComparedStats.splice(index, 1);
        setComparedStats(newComparedStats);
        break;
    }
  }

  const updateTimePeriodValue = (index: number, value: Date | null, clickedFrom: string | null) => {
    const newComparedDates = [...comparedDates];
    clickedFrom === "from"
      ? newComparedDates[index].from = value
      : newComparedDates[index].to = value;
    setComparedDates(newComparedDates);
  }

  const openCalendarFromInput = (index: number, clickedFrom: string) => {
    const newComparedDates = [...comparedDates];
    newComparedDates[index].calendarOpen = true;
    newComparedDates[index].clickedFrom = clickedFrom;
    setComparedDates(newComparedDates);
  }

  const closeCalendar = (index: number) => {
    const newComparedDates = [...comparedDates];
    newComparedDates[index].calendarOpen = false;
    setComparedDates(newComparedDates);
  }

  const fetchInitialDataForComparison = async (timePeriods: {from: Date | null, to: Date | null}[], metrics: string[], workoutProtocol: string) => {
    setProcessingComparison(true);
    setHasAttemptedComparison(true);
    setComparisonExercise(workoutProtocol);
    // Calculate comparison
    let chartData = [] as {[key: string]: number}[];

    if (timePeriods.length > 1) {
      console.log("In here")
      for (let i = 0; i < timePeriods.length; i++) {
        if (!timePeriods[i].from || !timePeriods[i].to) continue;
        
        let workoutsForPeriod = ParticipantsAssessments.filter(assessment => {
          let timestampAsDate = new Date(assessment.createdAt.seconds * 1000);

          return (
            timestampAsDate >= timePeriods[i].from! && 
            timestampAsDate <= timePeriods[i].to! &&
            assessment.workoutProtocol === workoutProtocol
          );
        });
        console.log(workoutsForPeriod);

        if (workoutsForPeriod.length === 0) continue;
        workoutsForPeriod.reverse();
        
        let metricsObject = {name: chartData.length + 1} as {[key: string]: number};
        let numberOfReps = 0;
        for (const metric of metrics) metricsObject[metric] = 0;

        for (const workout of workoutsForPeriod) {
          let fetchedExerciseSets = await fetchExerciseSetsByWorkoutId(workout.refId);
          if (!fetchedExerciseSets) continue;
          console.log(fetchedExerciseSets);

          for (const set of fetchedExerciseSets) {
            if (!set.Reps) continue;
            // If current date already exists in comparisonDataObject, add the sets to the existing array
            

            for (const rep of set.Reps) {
              if (comparisonLoad && rep.concentricMovement.mass !== comparisonLoad) continue;
              for (let i = 0; i < metrics.length; i++) {
                let metricName = metrics[i];
                let metricValue = rep.concentricMovement[metricName as keyof typeof rep.concentricMovement];
                if (metricValue && typeof metricValue === "number") {
                  metricsObject[metricName] += metricValue;
                }
              }
              numberOfReps++;
            }
          }
        }

        for (const metric in metricsObject) {
          if (metric !== "name") metricsObject[metric] = metricsObject[metric] / numberOfReps;
        }
        
        chartData.push(metricsObject);
      }
    }
    else {
      console.log("This one instead")
      let daysWithAssessments = [] as {date: string, includeInComparison: boolean, expanded: boolean, assessments: [{assessment: Assessment, includeInComparison: boolean}]}[];

      let assessmentsInSelectedDates = ParticipantsAssessments.filter(assessment => {
        let timestampAsDate = new Date(assessment.createdAt.seconds * 1000);

        return (
          timestampAsDate >= timePeriods[0].from! && 
          timestampAsDate <= timePeriods[0].to! &&
          assessment.workoutProtocol === workoutProtocol
        );
      });

      console.log(assessmentsInSelectedDates, timePeriods[0].from, timePeriods[0].to, workoutProtocol)

      if (assessmentsInSelectedDates.length === 0) {
        setComparisonData([]);
        return;
      }
      assessmentsInSelectedDates.reverse();
      
      outerLoop: for (const assessment of assessmentsInSelectedDates) {
        let assessmentSets = await fetchExerciseSetsByWorkoutId(assessment.refId);    
            
        if (assessmentSets && assessmentSets.length > 0) assessment.sets = assessmentSets;
        else continue;
        
        for (const set of assessment.sets) {
          if (!set.Reps || set.Reps.length === 0) continue outerLoop;

          set.includeInComparison = true;
          for (const rep of set.Reps) rep.includeInComparison = true;
        }
        
        const workoutDate = format(new Date(assessment.createdAt.seconds * 1000), 'MM/dd/yyyy');

        const index = daysWithAssessments.findIndex(day => day.date === workoutDate);
        index !== -1
          ? daysWithAssessments[index].assessments.push({assessment, includeInComparison: true})
          : daysWithAssessments.push({date: workoutDate, includeInComparison: true, expanded:false, assessments: [{assessment, includeInComparison: true}]});
      }

      console.log(daysWithAssessments)
      setComparisonData(daysWithAssessments);
    }
  }

  useEffect(() => {
    console.log("Comparison data changed")
    console.log(comparisonData);
    if (comparisonData.length === 0) {
      setComparisonChartData([]);
      setProcessingComparison(false);
      return
    };

    let chartData = [] as {[key: string]: number}[];

    for (const timePeriod of comparisonData) {
      if (!timePeriod.includeInComparison) continue;
      timePeriod.values = { name: timePeriod.date } as {[key: string]: number};
      
      // Set initial value for each metric in comparison
      for (const metric of comparedStats) timePeriod.values[metric] = 0;

      for (const assessmentObject of timePeriod.assessments) {
        if (!assessmentObject.includeInComparison || !assessmentObject.assessment.sets) continue;

        assessmentObject.values = { ...timePeriod.values };

        for (const set of assessmentObject.assessment.sets) {
          if (!set.includeInComparison || !set.Reps) continue;

          set.values = { ...timePeriod.values };
          for (const metric in set.values) {
            if (metric !== "name") {
              set.values[metric] = getMetricAverageFromArray(set.Reps.filter((rep: any) => rep.includeInComparison && rep[movementType]), movementType, metric);
            }
          }
        }

        for (const metric in assessmentObject.values) {
          if (metric !== "name") {
            assessmentObject.values[metric] = getMetricAverageFromArray(assessmentObject.assessment.sets.filter((set: any) => set.includeInComparison), movementType, metric);
          }
        }
      }

      for (const metric in timePeriod.values) {
        if (metric !== "name") {
          timePeriod.values[metric] = getMetricAverageFromArray(timePeriod.assessments.filter((assessment: any) => assessment.includeInComparison), movementType, metric);
        }
      }
      chartView === "Line" && chartData.push(timePeriod.values);
    }

    if (chartView === "VSD") {
      const comparisonDataCopy = JSON.parse(JSON.stringify(comparisonData));

      for (const timePeriod of comparisonDataCopy) {
        if (!timePeriod.includeInComparison) continue;
        timePeriod.values = {
          name: timePeriod.date,
          eccentricPeakVelocityTime: 0,
          eccentricPeakSpeed: 0,
          eccentricInversePop200: 0,
          eccentricInversePop100: 0,
          concentricPeakVelocityTime: 0,
          concentricPeakSpeed: 0,
          concentricPop100: 0,
          concentricPop200: 0
        };

        for (const workout of timePeriod.workouts) {
          if (!workout.includeInComparison || !workout.sets) continue;

          workout.values = { ...timePeriod.values };

          for (const set of workout.sets) {
            console.log(set);
            if (!set.includeInComparison || !set.Reps) continue;

            set.values = { 
              eccentricPeakVelocityTime: getMetricAverageFromArray(set.Reps, "eccentricMovement", "peakVelocityTime"),
              eccentricPeakSpeed: getMetricAverageFromArray(set.Reps, "eccentricMovement", "peakSpeed"),
              eccentricInversePop200: getMetricAverageFromArray(set.Reps, "eccentricMovement", "inversePop200"),
              eccentricInversePop100: getMetricAverageFromArray(set.Reps, "eccentricMovement", "inversePop100"),
              concentricPeakVelocityTime: getMetricAverageFromArray(set.Reps, "concentricMovement", "peakVelocityTime"),
              concentricPeakSpeed: getMetricAverageFromArray(set.Reps, "concentricMovement", "peakSpeed"),
              concentricPop100: getMetricAverageFromArray(set.Reps, "concentricMovement", "pop100"),
              concentricPop200: getMetricAverageFromArray(set.Reps, "concentricMovement", "pop200")
            };
            
          }

          for (const metric in workout.values) {
            if (metric !== "name") {
              workout.values[metric] = getMetricAverageFromArray(workout.sets.filter((set: any) => set.includeInComparison), "concentricMovement", metric);
            }
          }
        }

        for (const metric in timePeriod.values) {
          if (metric !== "name") {
            timePeriod.values[metric] = getMetricAverageFromArray(timePeriod.workouts.filter((workout: any) => workout.includeInComparison), "concentricMovement", metric);
          }
        }
        chartData.push(timePeriod.values);
      }
    }

    chartView === "VSD" 
      ? setComparisonChartData(chartData.map((data, index) => {
          let ePKV = (data.eccentricPeakVelocityTime - (2 * data.eccentricPeakVelocityTime)) * 1000;
          let cPKV = data.concentricPeakVelocityTime * 1000;
          let label = data.name;
          return [
            { x: ePKV, y: data.eccentricPeakSpeed, label },
            { x: -200, y: data.eccentricInversePop200, label },
            { x: -100, y: data.eccentricInversePop100, label },
            { x: 0, y: 0, label },
            { x: 100, y: data.concentricPop100, label },
            { x: 200, y: data.concentricPop200, label },
            { x: cPKV, y: data.concentricPeakSpeed, label }
          ].sort((a, b) => a.x - b.x);
        }))
      : setComparisonChartData(chartData);
    setProcessingComparison(false);
  }, [comparisonData, chartView]);

  const options = (stat: keyof typeof metricsForComparison) => {
    return <>
      <option key={stat} value={stat}>
        {metricsForComparison[stat]}
      </option>
      {Object.keys(metricsForComparison) 
        .filter((metric) => !comparedStats.includes(metric))
        .map((metric) => (
          <option key={metric} value={metric}>
          {metricsForComparison[metric as keyof typeof metricsForComparison]}
          </option>
      ))}
    </>
  }

  useEffect(() => {
    setHasAttemptedComparison(false);
    setComparisonChartData([]);
    setComparisonData([]);
    //console.log(comparedStats)
  }, [comparedDates, comparedStats, comparisonLoad]);

  useEffect(() => {
    console.log(processingComparison, comparisonChartData)
  }, [comparisonChartData, processingComparison]);

  const metricsGrid = (values: any) => (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
      {comparedStats.map((metric, index) => (
        <div className="text-center border border-[#1c1f2c] rounded-md overflow-hidden">
          <div className="bg-[#1c1f2c] text-white rounded-t-md px-2 py-1">
            <h3>{metricsForComparison[metric as keyof typeof metricsForComparison]}</h3>
          </div>
          <div className="p-2 bg-white">
            <h4>{formatStatValue(metric, values[metric])}</h4>
          </div>
        </div>
      ))}
    </div>
  );

  return (<>
    <div className="my-4 flex flex-col gap-6 card p-4">
      <div className="flex items-center gap-4">
        <h3 className="shrink-0 w-40">Exercise:</h3>
        <div className="relative flex items-start flex-grow">
          <div 
            className="flex items-center gap-2 text-lg cursor-pointer" 
            onClick={() => setSelectExerciseOpen(!selectExerciseOpen)}
          >
            <img 
              className="w-10" 
              src={`/exercise_icons/${imageForWorkoutProtocol(selectedExercise)}.png`} 
              alt={selectedExercise} 
            />
            
            <h3>{selectedExercise}</h3>
            <KeyboardArrowDownIcon />
          </div>

          {selectExerciseOpen && <ExerciseSelect selectedExercise={selectedExercise} setSelectedExercise={setSelectedExercise} setModalVisible={setSelectExerciseOpen}  />}
        </div>
      </div>

      <div className="flex gap-4">
        <h3 className="shrink-0 w-40">Time Period{comparedDates.length > 1 && <>s</>}:</h3>
        <div className="flex flex-wrap gap-2 items-center">
          {comparedDates.map((comparedDate, index) => (
            <div className="flex border border-[#1c1f2c] rounded-lg gap-4 relative pr-2">
              <button className="bg-transparent py-2 px-2 outline-none cursor-pointer border-r border-[#1c1f2c]" onClick={() => openCalendarFromInput(index, "from")}>
                From:&nbsp;{comparedDate.from ? format(comparedDate.from, 'MM/dd/yyyy') : "-"}
              </button>

              <button className="bg-transparent py-2 outline-none cursor-pointer" onClick={() => openCalendarFromInput(index, "to")}>
                To:&nbsp;{comparedDate.to ? format(comparedDate.to, 'MM/dd/yyyy') : "-"}
              </button>
              
              {/*<button className="text-2xl bg-white text-black px-2 cursor-pointer rounded-r-lg flex items-center justify-center" onClick={() => spliceByIndex(index, "dates")} >&times;</button>*/}
              {comparedDate.calendarOpen && 
                <ComparisonCalendar 
                  setSelectedDate={(date: Date | null) => updateTimePeriodValue(index, date, comparedDate.clickedFrom)}
                  selectedDate={comparedDate.clickedFrom === "from" ? comparedDate.from : comparedDate.to}
                  closeCalendar={() => closeCalendar(index)}
                />
              }
            </div>
          ))}

          {/*
          <button 
            className="px-3 bg-white py-2 text-black uppercase font-semibold text-sm rounded-lg"
            onClick={() => setComparedDates([...comparedDates, {from: null, to: null, calendarOpen: false, clickedFrom: null}])}
          >
            Add another time period
          </button>
          */}
        </div>
      </div>

      <div className="flex items-center gap-4">
        <h3 className="shrink-0 w-40">Movement:</h3>
        <div className="relative flex items-start flex-grow">
          <div className="flex border border-[#1c1f2c] rounded-lg relative overflow-hidden">
              <button 
                className={`py-2 px-2 outline-none cursor-pointer border-r border-[#1c1f2c] ${movementType === "concentricMovement" ? "bg-[#1c1f2c] text-white" : "bg-transparent text-black"}`}
                onClick={() => setMovementType("concentricMovement")}
              >
                Concentric
              </button>

              <button 
                className={`py-2 px-2 outline-none cursor-pointer ${movementType === "eccentricMovement" ? "bg-[#1c1f2c] text-white" : "bg-transparent text-black"}`}
                onClick={() => setMovementType("eccentricMovement")}
              >
                Eccentric
              </button>
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <h3 className="shrink-0 w-40">Metrics to compare:</h3>
        <div className="flex flex-wrap gap-2 items-center">
          {comparedStats.map((comparedStat, index) => (
            <div className="flex border border-[#1c1f2c] rounded-lg gap-4 overflow-hidden">
              <select 
                className="bg-transparent py-2 pl-2 outline-none cursor-pointer"
                value={metricsForComparison[comparedStat as keyof typeof metricsForComparison]}
                onChange={e => {
                  const newComparedStats = [...comparedStats];
                  console.log(newComparedStats, index, e.target.value)
                  newComparedStats[index] = e.target.value;
                  setComparedStats(newComparedStats);
                }}
              >
                {options(comparedStat as keyof typeof metricsForComparison)}
              </select>
              <button className="text-2xl bg-mfx-blue text-white px-2 cursor-pointer flex items-center justify-center" onClick={() => spliceByIndex(index, "metrics")} >&times;</button>
            </div>
          ))}
          <button 
            className="px-3 bg-mfx-blue text-white py-2 uppercase font-semibold text-sm rounded-lg"
            onClick={() => setComparedStats([...comparedStats, Object.keys(metricsForComparison).filter((metric) => !comparedStats.includes(metric))[0]])}
          >
            Add another metric
          </button>
        </div>
      </div>

      <div className="flex flex-wrap gap-4">
        <h3 className="shrink-0 w-40">Workout Load:</h3>
        <div className="flex items-center">
          <input 
            type="number" 
            className="px-2 py-1 border-l border-y border-[#1c1f2c] rounded-l-lg h-full text-white" 
            placeholder="Enter workout load" 
            onChange={e => setComparisonLoad(Number(e.target.value))}
          />
          <div className="border border-[#1c1f2c] bg-[#1c1f2c] text-white rounded-r-lg pr-2">
            <select 
              className="bg-transparent py-2 pl-2 outline-none cursor-pointer"
              value={loadUnit}
              onChange={e => setLoadUnit(e.target.value)}
            >
              <option value="lbs">lbs</option>
              <option value="kg">kg</option>
            </select>
          </div>
        </div>
      </div>  

      <button
        disabled={comparedDates.some(date => !date.from || !date.to) || comparedStats.length === 0 || processingComparison}
        className="px-3 py-2 bg-mfx-blue text-white uppercase font-bold text-sm rounded-lg"
        onClick={() => fetchInitialDataForComparison(comparedDates.map(date => ({from: date.from, to: date.to})), comparedStats, selectedExercise)}
      >
        Generate Performance Comparison
      </button>
    </div>

    {processingComparison && (
      <div className="flex items-center flex-col items-center justify-center gap-6 py-6 mt-24 w-full">
        <i className="fas fa-spinner fa-spin text-6xl"></i>
        <h3 className="font-medium text-2xl">Generating comparison...</h3>
      </div>
    )}

    {!processingComparison && hasAttemptedComparison && (
      comparisonChartData.length > 0 
        ? <>
            <div className="card w-full p-4">
              <div className="flex items-center gap-4 mb-6">
                <h3 className="text-xl font-bold text-center">{comparisonExercise}&nbsp;{ucFirst(movementType.replace("Movement", ""))}&nbsp;Performance{comparisonLoad && <span>&nbsp;-&nbsp;{comparisonLoad}&nbsp;{loadUnit}</span>}</h3>

                <div 
                  className="ml-auto border border-white rounded-lg p-2 cursor-pointer" 
                  onClick={() => {
                    setProcessingComparison(true);
                    setChartView(chartView === "Line" ? "VSD" : "Line")}
                  }
                >
                  {chartView === "Line" ? <><InsightsIcon /><span className="ml-1">Velocity Sequence</span></> : <><SsidChartIcon /><span className="ml-1">Line Chart</span></>}
                </div>
              </div>

              {chartView === "Line" 
                ? <ResponsiveContainer width="100%" height={400} className="mb-6">
                    <LineChart data={comparisonChartData}>
                      <XAxis dataKey="name" tick={{ fill: 'black' }} />
                      <CartesianGrid stroke="#797979" />
                      <YAxis tick={{ fill: 'black' }}  />
                      <Tooltip 
                        contentStyle={{ backgroundColor: '#444444', color: 'white' }} 
                        itemStyle={{ color: 'white' }} 
                        formatter={(value: number, name: string) => {
                          let formattedValue = formatStatValue(name, value); 
                          return [formattedValue, name];
                        }}
                      />
                      {comparedStats.map((metric, index) => (
                        <Line 
                          type="monotone" 
                          dataKey={metric} 
                          stroke={lineChartColorCodes[index]}
                          strokeWidth={2} 
                          key={index} 
                        />
                      ))}
                      <Legend />
                    </LineChart>
                  </ResponsiveContainer>
                : <div className="w-full mt-6">
                    <VelocitySequenceDiagram chartData={comparisonChartData} viewLevel="Dates" />
                  </div>
              }
            </div>

            {comparisonData.map((data: any, timePeriodIndex: number) => (
              <div key={data.date} className="card p-4 mt-4 rounded-lg">
                <div className="flex items-center gap-4 mb-2 text-center">
                  <h3>Workouts on {data.date}</h3>

                  <div
                    className="ml-auto cursor-pointer flex items-center gap-2"
                    onClick={() => {
                      const newComparisonData = [...comparisonData];
                      newComparisonData[timePeriodIndex].expanded = !data.expanded;
                      setComparisonData(newComparisonData);
                    }}
                  >
                    {data.expanded
                      ? <><p>Show less</p><i className="fa-solid fa-caret-up text-lg cursor-pointer"></i></>
                      : <><p>Show more</p><i className="fa-solid fa-caret-down text-lg cursor-pointer"></i></>
                    }
                  </div>
                  {comparisonData.length > 1 && (
                    <button
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        const newComparisonData = [...comparisonData];
                        newComparisonData[timePeriodIndex].includeInComparison = !data.includeInComparison;
                        setComparisonData(newComparisonData);
                      }}
                    >
                      <div className={`toggle-switch ${data.includeInComparison ? 'on' : 'off'}`}>
                        <div className="toggle-knob"></div>
                      </div>
                  </button>
                  )}
                </div>

                {metricsGrid(data.values)}
                
                {data.includeInComparison && data.expanded && data.assessments.map((assessment: any, assessmentIndex: number) => (
                  <div className="bg-[#eef2f8] rounded-lg mb-2 p-2">
                    <div className="flex items-center gap-4 p-2">
                      <h2>Workout {assessmentIndex + 1}</h2>

                      <div
                        className="ml-auto cursor-pointer flex items-center gap-2"
                        onClick={() => {
                          const newComparisonData = [...comparisonData];
                          newComparisonData[timePeriodIndex].assessments[assessmentIndex].expanded = !assessment.expanded;
                          setComparisonData(newComparisonData);
                        }}
                      >
                        {assessment.expanded
                          ? <><p>Show less</p><i className="fa-solid fa-caret-up text-lg cursor-pointer"></i></>
                          : <><p>Show more</p><i className="fa-solid fa-caret-down text-lg cursor-pointer"></i></>
                        }
                      </div>
                      
                      <div>
                        <button
                          className="ml-2 cursor-pointer"
                          onClick={() => {
                            const newComparisonData = [...comparisonData];
                            newComparisonData[timePeriodIndex].assessments[assessmentIndex].includeInComparison = !assessment.includeInComparison;
                            setComparisonData(newComparisonData);
                          }}
                        >
                          <div className={`toggle-switch ${assessment.includeInComparison ? 'on' : 'off'}`}>
                            <div className="toggle-knob"></div>
                        </div>
                        </button>
                      </div>
                    </div>

                    {metricsGrid(assessment.values)}

                    {assessment.includeInComparison && assessment.expanded && assessment.assessment.sets.map((set: any, setIndex: number) => (
                      <div className="card p-2 rounded-lg mb-2">
                        <div className="flex items-center gap-4 p-2">
                          <h3>Set {setIndex + 1}</h3>

                          <div
                            className="ml-auto cursor-pointer flex items-center gap-2"
                            onClick={() => {
                              const newComparisonData = [...comparisonData];
                              newComparisonData[timePeriodIndex].assessments[assessmentIndex].assessment.sets[setIndex].expanded = !set.expanded;
                              setComparisonData(newComparisonData);
                            }}
                          >
                            {set.expanded
                              ? <><p>Show less</p><i className="fa-solid fa-caret-up text-lg cursor-pointer"></i></>
                              : <><p>Show more</p><i className="fa-solid fa-caret-down text-lg cursor-pointer"></i></>
                            }
                          </div>

                          <div className="mr-2">
                            <button
                              className="ml-2 cursor-pointer"
                              onClick={() => {
                                const newComparisonData = [...comparisonData];
                                newComparisonData[timePeriodIndex].assessments[assessmentIndex].assessment.sets[setIndex].includeInComparison = !set.includeInComparison;
                                setComparisonData(newComparisonData);
                              }}
                            >
                              <div className={`toggle-switch ${set.includeInComparison ? 'on' : 'off'}`}>
                                  <div className="toggle-knob"></div>
                              </div>
                            </button>

                          </div>
                        </div>

                        {metricsGrid(set.values)}

                        {set.includeInComparison && set.expanded && set.Reps.map((rep: any, index: number) => (
                          <div className="bg-[#eef2f8] p-2 mb-2 rounded-lg">
                            <div className="flex items-center justify-between p-2">
                              <h4>Rep {index + 1}</h4>

                              <div>
                                <button
                                  className="ml-2 cursor-pointer"
                                  onClick={() => {
                                    const newComparisonData = [...comparisonData];
                                    newComparisonData[timePeriodIndex].assessments[assessmentIndex].assessment.sets[setIndex].Reps[index].includeInComparison = !rep.includeInComparison;
                                    setComparisonData(newComparisonData);
                                  }}
                                >
                                  <div className={`toggle-switch ${rep.includeInComparison ? 'on' : 'off'}`}>
                                      <div className="toggle-knob"></div>
                                  </div>
                                </button>
                              </div>
                            </div>

                            {metricsGrid(rep.concentricMovement)}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </>
        : <div className="flex items-center flex-col items-center justify-center gap-6 py-6 mt-24 w-full">
            <i className="fa-solid fa-person-circle-exclamation text-6xl"></i>
            <h3 className="font-medium text-2xl">No {comparisonExercise} workouts matching your criteria...</h3>
          </div>
    )}
  </>)
}

export default PerformanceComparison;